import { useQuery } from "@tanstack/react-query";

import { PublicInformation } from "helpers/queriesDataKeys";
import { API_BASE_URL } from "configs/AppConfig";

import NavList from "./NavList";

import classes from "./Footer.module.css";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const FaqList = [{ name: "FAQ", to: "/faq" }];
const ConnectNavList = [{ name: "Contact Us", to: "/contact-us" }];

const Footer = () => {
  const user = useSelector((state) => state.user);
  const [categoryData, setCategoryData] = useState([]);
  const [eventData, setEventData] = useState([]);

  const fetchcategories = async () => {
    const response = await fetch(`${API_BASE_URL}category/get/all/admin`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${user.sessionToken}`,
      },
      // body: JSON.stringify(),
    });

    const data = await response.json();
    setCategoryData(
      data.data.map((item) => ({ label: item.name, value: item._id }))
    );
    console.log("response", data);
    if (!response.ok) {
      // message.error(data.message ? data.message : "Invalid Email or Password");
      throw new Error(data.message);
    }

    return data;
  };

  const fetchEvents = async () => {
    const response = await fetch(`${API_BASE_URL}eventType/get/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${user.sessionToken}`,
      },
      // body: JSON.stringify(),
    });

    const data = await response.json();
    setEventData(data.data);
    console.log("response", data);
    if (!response.ok) {
      // message.error(data.message ? data.message : "Invalid Email or Password");
      throw new Error(data.message);
    }

    return data;
  };

  useEffect(() => {
    fetchcategories();
    fetchEvents();
  }, [user]);
  // const {
  //   isLoading,
  //   isError,
  //   data: informations,
  //   error,
  // } = useQuery([PublicInformation], async () => {
  //   const response = await fetch(`${API_BASE_URL}information/get/all`);

  //   const data = await response.json();
  //   if (!response.ok) {
  //     throw new Error(data.message);
  //   }

  //   return data;
  // });

  const location = useLocation();
  const isCart = location.pathname.includes("cart");
  console.log("isCart", location.pathname.includes("cart"));
  const isCheckout = location.pathname.includes("checkout");

  return (
    <>
      {!(isCart || isCheckout) && (
        <div className={classes.footer}>
          <div className={classes.footerContent}>
            <div className={classes.footerLeft}>
              <Link to="/">
                {/* <div className={classes.siteIcon}></div> */}
                <img
                  src="/img/logo-footer.svg"
                  alt="Logo"
                  loading="lazy"
                  className={classes.siteIcon}
                />
              </Link>
              <p>
                Discover and book everything you need for your special event in
                one convenient place! From photographers to planners, make your
                occasion unforgettable with ease.
              </p>
            </div>
            <div className={classes.footerRight}>
              <div className={classes.footerItems}>
                <h3>Category</h3>

                {categoryData?.slice(0, 5)?.map((item) => (
                  <Link to={`/search?categoryId=${item.value}`}>
                    <p>{item.label}</p>
                  </Link>
                ))}
              </div>
              <div className={classes.footerItems}>
                <h3>Event Type</h3>
                {eventData?.slice(0, 5)?.map((item) => (
                  <Link to={`/search?eventType=${item.name}`}>
                    <p>{item.name}</p>
                  </Link>
                ))}
              </div>
              <div className={classes.footerItems}>
                <h3>Connect With Us</h3>
                <p>Facebook</p>
                <p>Twitter</p>
                <p>Instagram</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={classes.footerBottom}>
        <p>
          Copyright Wedding © {new Date().getFullYear()} v1.2.2. All rights
          reserved | Developed By{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://riolabz.com/", "_blank")}
          >
            Riolabz
          </span>
          . All rights reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
